import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class DataService {

  zoneid: any;
  private planData = new BehaviorSubject<any>({});
  private url = environment.keycloakConfig.url;
  year: any;
  originName: any;
  public allApi = '/api'
  public userApiNew = '/v1/user';
   private createUser = environment.userServiceApi + '/create';
   private getUser = environment.userServiceApi + '/getUsers';
   private editUser = environment.userServiceApi + '/update';
  private updateStatusurl = environment.userServiceApi + '/updateStatus';
  private roleUrl = environment.userServiceApi + '/getBanner';
  private forgetUrl = environment.forgetPasswordApi + '/forgotPassword/';
  private changePasswordUrl = environment.forgetPasswordApi + '/changePassword';
  private scoreCardUrl = environment.defaultApi + '/siwpscrcard/api/v1/scrcard/getData';
  private consolidateScoreCardUrl = environment.defaultApi + '/siwpscrcard/api/v1/scrcard/consSearch';
  private yearlyScoreCardUrl = environment.defaultApi + '/siwpscrcard/api/v1/scrcard/yearlySearch';
  private investorCompanyUrl = environment.defaultApi + '/siwpmaster/api/v1/master/getInvestorCompany';
  private consScoreUrl = environment.defaultApi + '/siwpscrcard/api/v1/scrcard/consScore';
  private profileViewUrl = environment.defaultApi + '/siwpscrcard/api/v1/scrcard/profileView';
  // private createUser = this.allApi + this.userApiNew + "/create";
  // private getUser = this.allApi + this.userApiNew + "/getUsers";
  // private editUser = this.allApi + this.userApiNew + "/update";
  // private updateStatusurl = this.allApi + this.userApiNew + "/updateStatus";

  // private editUser = "http://localhost:9183/siwpauth/api/v1/user/update";
  constructor(private http:HttpClient){}
  httpOptions = {
      headers: new HttpHeaders({
          'Access-Control-Allow-Methods':'DELETE, POST, GET, OPTIONS, PUT',
          'Access-Control-Allow-Headers':'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
          'Content-Type':  'application/json',
          'Access-Control-Allow-Origin':'https://siwp-api-dev.olamdigital.com'
      })
    };
  
    // const dataObj = JSON.parse(sessionStorage.getItem('dataObj'))
    // if (dataObj) {
    //   if (this.endr.getDecryptedData(dataObj.zoneId) == null) {
    //     this.zoneid = Number(this.endr.getDecryptedData(dataObj.zoneId));
    //   } else {
    //     this.zoneid = 0;
    //   }

    //   this.year = this.endr.getDecryptedData(dataObj.farmerYear);
    //   this.originName = this.endr.getDecryptedData(dataObj.originName ? dataObj.originName : '7x7Kcenod2s/b+Vm0YHJIw==');
    // }
  
   // common methods

   get(url: string) {
    let api_Url: string = this.url + url; 
        // return this.http.get(api_Url);
  }
  getUsers(){
    let api_Url: string = `${this.getUser}`
    return this.http.get(api_Url, this.httpOptions)
  }

  editUserData(data: any){
    const httpOptions = {
      headers: new HttpHeaders({
        //'Content-Type': 'multipart/form-data',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      })
    };
    let api_Url: string = `${this.editUser}`
    console.log(data)
    return this.http.put(api_Url, data, this.httpOptions)

  }

  updateStatus(data: any){
    const httpOptions = {
      headers: new HttpHeaders({
        //'Content-Type': 'multipart/form-data',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      })
    };
    let api_Url: string = `${this.updateStatusurl}`
    return this.http.put(api_Url, data, this.httpOptions)
  }

  getData(data:any , url: string) {
    let api_Url: string = this.url + url; 
    return this.http.post(api_Url, data);
  } 

  getInvestorData() {
    let api_Url: string = this.investorCompanyUrl; 
    return this.http.get(api_Url);
  }  


  post(data:any ,url:string) {
    let api_Url: string = this.url + url; 
    return this.http.post(api_Url, data);
  }
  //manual entry --starts here
  getAtsourceAgridata(data:any) {
    let api_Url: string = `${this.url}/GetPrimaryData` 
    return this.http.post(api_Url, data);
  }

  saveAtsourceAgriData(data:any) {
    let api_Url: string = `${this.url}/SavePrimaryData` 
    return this.http.post(api_Url, data);
  }

  updateAtsourceAgriData(data:any) {
    let api_Url: string = `${this.url}/UpdatePrimaryData`
    return this.http.put(api_Url, data)
  }
  //agriculture
  getManualAgridata(data:any){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'multipart/form-data',
        // 'Access-Control-Allow-Origin': '*',
      })
    };
    let api_Url:string = `${this.url}/getAtsourceManualEntry`
    return this.http.post(api_Url,data, httpOptions)
  }
  saveAtsourceManualEntry(data:any){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'multipart/form-data',
      })
    };
    let api_Url:string = `${this.url}/saveAtsourceManualEntry`
    return this.http.post(api_Url,data, httpOptions)
  }
  updateAtsourceManualEntry(data:any){
    let api_Url:string = `${this.url}/updateAtsourceManualEntry`
    return this.http.put(api_Url,data)
  }
  //Processing

 

  saveProcessingManualEntry(data:any) {
    let api_Url: string = `${this.url}/saveProcessingManualEntry` 
    return this.http.post(api_Url, data);
  }

  updateProcessingManualEntry(data:any) {
    let api_Url: string = `${this.url}/updateProcessingManualEntry`
    return this.http.put(api_Url, data)
  }

  getManualProcessingdata(data:any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'multipart/form-data',
      })
    };
    let api_Url: string = `${this.url}/getProcessingManualEntry` 
    return this.http.post(api_Url, data, httpOptions);
  }

  //manual entry -----ends
  //inflation --starts here
  getInflation() {
    let api_Url: string = `${this.url}/getInflation` 
    return this.http.get(api_Url);
  }

  saveInflation(data:any) {
    let api_Url: string = `${this.url}/saveInflation` 
    return this.http.post(api_Url, data);
  }

  getRole(email: string){
    let api_Url: string = `${this.roleUrl}`;
    return this.http.get(api_Url);
  }

  updateInflation(data:any) {
    let api_Url: string = `${this.url}/updateInflation`
    return this.http.put(api_Url, data)
  }
  //inflation---ends here
  
  //primary data

  getPrimaryDate(data:any) {
    let api_Url: string = `${this.url}/primaryDate`;
    return this.http.post(api_Url, data);
  }

  // getBusinessUnitDetails(data:any) {
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'Access-Control-Allow-Origin': '*',
  //     })
  //   };
   
  //   let api_Url: string = `${this.url}/primaryDetails`
  //   return this.http.get(api_Url, data)
  // }

  getPrimaryDatedata(data:any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      })
    };
   
    let api_Url: string = `${this.url}/primaryDetails`
    return this.http.post(api_Url, data)
  }
  
  //user management 
  getUserData() {
    let api_Url: string = `${this.url}/UserViewDetails?start=1&limit=50` //'http://10.101.57.7:5000/UserViewDetails?start=11&limit=10' //`${this.url}/user/userdetails/getAll`
    // api_Url = "http://10.101.57.7:5000/UserViewDetails?start=1&limit=10"
    return this.http.get(api_Url);
  }

  // editUserData(data:any) {
  //   let api_Url: string = `${this.url}/editUser`
  //   return this.http.put(api_Url, data)
  // }

  saveUserData(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        //'Content-Type': 'multipart/form-data',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      })
    };
    let api_Url: string = `${this.createUser}`
    return this.http.post(api_Url, data)
  }
  
  getUserDetailsbyID(id: number, data:any) {
    // 'Content-Type': 'application/json'
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      })
    };
    //let api_Url: string = `${this.url}/UserViewDetailsById?userid=${id}` //UserViewDetailsById?userid=1
    let api_Url: string = `${this.url}/UserViewDetailsById/`
    // api_Url = `http://10.101.57.7:5000/UserViewDetailsById?userid=${id}`
    
    return this.http.post(api_Url,data, httpOptions);
  }

  getUserDetailsbyMailID(data: any) {
    // 'Content-Type': 'application/json'
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'Content-Type': 'application/json',
    //     'Access-Control-Allow-Origin': '*',
    //   })
    // };
    let api_Url: string = `${this.url}/UserViewDetailsById/` //UserViewDetailsById?userid=1
    
    // api_Url = "http://10.101.160.167:5000/UserViewDetailsById/"
    return this.http.post(api_Url, data);
  }

  removeUserData(data:any) {   

    // this.http.post("url", formData) 
    let api_Url: string = `${this.url}/DeleteRecord`
    return this.http.put(api_Url, data);
  }

  userStatusData(data:any) {   
    // this.http.post("url", formData) 
    let api_Url: string = `${this.url}/StatusRecord`
    return this.http.put(api_Url, data);
  }
  //user management

  //question service 

  getType() {
    let api_Url: string = `${this.url}/question/questionstype/getAll`
    return this.http.get(api_Url)
  }

  getQuesByID(id: any, type: any) {
    if (type == 'farmer') {
      let api_Url: string = `${this.url}/question/farmersdetailsquestions/get`
      return this.http.get(api_Url + '/' + id)
    }
    if (type == 'farm') {
      let api_Url: string = `${this.url}/question/farmdetailsquestions/get`
      return this.http.get(api_Url + '/' + id)
    }

    if (type == 'farmvisit') {
      let api_Url: string = `${this.url}/question/farmvisitquestions/get`
      return this.http.get(api_Url + '/' + id)
    }
  }

  DeleteQuesOption(id: any, type: any) {

    if (type == 'farmer') {
      let api_Url: string = `${this.url}/question/farmersdetailsquestions/options/delete`
      return this.http.delete(api_Url + '/' + id)
    }
    if (type == 'farm') {
      let api_Url: string = `${this.url}/question/farmdetailsquestions/options/delete`
      return this.http.delete(api_Url + '/' + id)
    }

    if (type == 'farmvisit') {
      let api_Url: string = `${this.url}/question/farmvisitquestions/options/delete`
      return this.http.delete(api_Url + '/' + id)
    }
  }

  addFarmerQues(data: any) {
    let api_Url: string = `${this.url}/question/farmersdetailsquestions/save`
    return this.http.post(api_Url, data)
  }

  getFarmerQues(data: any) {
    let api_Url: string = `${this.url}/question/getfarmerdetailsquestionsbyorigin`
    return this.http.get(api_Url + '/' + data)
  }

  DeleteFarmerQues(id: any) {
    let api_Url: string = `${this.url}/question/farmersdetailsquestions/delete`
    return this.http.delete(api_Url + '/' + id)
  }

  getFarmQues(data: any) {
    let api_Url: string = `${this.url}/question/getfarmdetailsquestionsbyorigin`
    return this.http.get(api_Url + '/' + data)
  }

  addFarmQues(data: any) {
    let api_Url: string = `${this.url}/question/farmdetailsquestions/save`
    return this.http.post(api_Url, data)
  }

  DeleteFarmQues(id: any) {
    let api_Url: string = `${this.url}/question/farmdetailsquestions/delete`
    return this.http.delete(api_Url + '/' + id)
  }

  getFarmVisitQues(data: any) {
    let api_Url: string = `${this.url}/question/getfarmvisitsquestionsbyorigin`
    return this.http.get(api_Url + '/' + data)
  }

  addFarmVisitQues(data: any) {
    let api_Url: string = `${this.url}/question/farmvisitquestions/save`
    return this.http.post(api_Url, data)
  }

  DeleteFarmVisitQues(id: any) {
    let api_Url: string = `${this.url}/question/farmvisitquestions/delete`
    return this.http.delete(api_Url + '/' + id);
  }

  getGroups() {
    let api_Url: string = `${this.url}/question/farmvisitgroups/getAll`
    return this.http.get(api_Url)
  }

  addGroups(data: any) {
    let api_Url: string = `${this.url}/question/farmvisitgroup/save`
    return this.http.post(api_Url, data)
  }

  //farmer service

  getSeasonalYear(val: any) {
    let api_Url: string = `${this.url}/farmer/getSeasonalyearByOrigin`
    return this.http.get(api_Url + '?originname=' + val);
  }

  // getFarmerData(orgin: any, year: any) {
  //   let api_Url: string = `${this.url}/farmer/getFieldstaffsAndDetails`
  //   return this.http.get(api_Url + '?originname=' + orgin + '&seasonalyear=' + year);
  // }

  getFarmerData(data: any) {
    let api_Url: string = `${this.url}/farmer/getFieldstaffsAndDetailsNew`
    return this.http.post(api_Url, data);
  }

  getFarmercapturedData(data: any, originName: any) {
    let api_Url: string = `${this.url}/farmer/getFieldstaffDetailsByFieldstaffid`
    return this.http.get(api_Url + '?originname=' + originName + '&seasonalyear=' + data.year + '&fieldstaffid=' + data.id);
  }

  getFarmerDetail(data: any, originName: any) {
    let api_Url: string = `${this.url}/farmer/getFarmerDetailsByFarmerId`
    return this.http.get(api_Url + '?originname=' + originName + '&seasonalyear=' + data.year + '&id=' + data.id);
  }

  getFarmViewDetail(data: any, originName: any) {
    let api_Url: string = `${this.url}/farmer/getFarmDetailsByFarmerid`
    return this.http.get(api_Url + '?originname=' + originName + '&seasonalyear=' + data.year + '&farmerid=' + data.id);
  }

  getFarmVisitData(orgin: any, year: any) {
    let api_Url: string = `${this.url}/farmer/getFarmVisitsBySeasonalyear`
    return this.http.get(api_Url + '?originname=' + orgin + '&seasonalyear=' + year);
  }

  getFarmVisitStaion(orgin: any, year: any) {
    let api_Url: string = `${this.url}/farmer/getFarmVisitMastersBySeasonalyear`
    return this.http.get(api_Url + '?originname=' + orgin + '&seasonalyear=' + year);
  }

  getFarmVisitViewData(data: any, originName: any) {
    let api_Url: string = `${this.url}/farmer/getFarmVistsByFarm`
    return this.http.get(api_Url + '?originname=' + originName + '&farmerid=' + data.id + '&farmid=' + data.farmid + '&year=' + data.year);
  }

  getGpxUploadData(orgin: any, year: any) {
    let api_Url: string = `${this.url}/farmer/getfieldstaffandfarmerfarmdetails`
    return this.http.get(api_Url + '?originname=' + orgin + '&seasonalyear=' + year);
  }

  getGpxFieldstaff() {
    let api_Url: string = `${this.url}/farmer/getFieldstaffsForGpx`
    return this.http.get(api_Url);
  }

  getDashboardData() {
    let api_Url: string = `${this.url}/farmer/getDashboardData`
    return this.http.get(api_Url);
  }

  getTableauScript() {
    return (this.http.get("/assets/js/tableau-script.js"));
  }

  reportExport(data: any) {
    let api_Url: string = `${this.url}/farmer/exportData`
    return this.http.post(api_Url, data, { responseType: 'blob' })
  }

  getotherDetails(data: any) {
    let api_Url: string = `${this.url}/farmer/exportFarmerDetails`
    return this.http.post(api_Url, data, { responseType: 'blob' })
  }

  // getFarmerMasterData() {
  //   let api_Url: string = `${this.url}/farmer/getFarmerMasterData`
  //   return this.http.get(api_Url);
  // }

  getFarmerMasterDatabyID(data: any) {
    let api_Url: string = `${this.url}/farmer/getFarmerMasterDataNew`
    return this.http.post(api_Url, data);
  }

  getFarmerdatabyId(id: any) {
    let api_Url: string = `${this.url}/farmer/getFarmerById`
    return this.http.get(api_Url + '/' + id);
  }

  getFarmDetailsByFarmerId(id: any) {
    let api_Url: string = `${this.url}/farmer/getFarmDetailsByFarmerId?`
    return this.http.get(api_Url + 'farmerId=' + id);
  }

  getFarmDetailsBySectionId(data: any) {
    let api_Url: string = `${this.url}/farmer/getFarmDetailsBySectionId`
    return this.http.post(api_Url, data);
  }

  getFieldStaffforUpdate() {
    let api_Url: string = `${this.url}/farmer/getFieldstaff`
    return this.http.get(api_Url);
  }

  getZoneSectionbyID(id: number) {
    let api_Url: string = `${this.url}/farmer/getZoneBySection`
    return this.http.get(api_Url + '/' + id);
  }

  getallVillageforFarmer(year: any) {
    let api_Url: string = `${this.url}/farmer/getVillages`
    return this.http.get(api_Url + '/' + year);
  }
  getCurrentyearcooperatives(year: any) {
    let api_Url: string = `${this.url}/farmer/getCurrentyearcooperatives`
    return this.http.get(api_Url + '/' + year);
  }

  getMastersbyVillageID(id: number) {
    let api_Url: string = `${this.url}/farmer/getOtherMastersByVillage`
    return this.http.get(api_Url + '/' + id);
  }

  getFarmersBySection(id: number, blockname: any) {
    let api_Url: string = `${this.url}/farmer/getFarmersBySection`
    return this.http.get(api_Url + '?sectionmasterId=' + id + '&blockName=' + blockname);
  }

  updateFarmerData(data: any) {
    let api_Url: string = `${this.url}/farmer/updateFarmer`
    return this.http.post(api_Url, data);
  }

  getFarmvisitnoByFarm(data: any) {
    let api_Url: string = `${this.url}/farmer/getFarmvisitnoByFarm`
    return this.http.get(api_Url + '?farmerid=' + data.id + '&farmno=' + data.no + '&seasonalyear=' + this.year);
  }

  saveBlocktoFarmer(data: any) {
    let api_Url: string = `${this.url}/farmer/saveBlocktoFarmer`
    return this.http.post(api_Url, data);
  }

  removeFarmerData(data: any) {
    let api_Url: string = `${this.url}/farmer/deactiveFarmer`
    return this.http.post(api_Url, data);
  }

  uploadFarmGpx(data: any) {
    let api_Url: string = `${this.url}/farmer/uploadFarmGpx`
    return this.http.post(api_Url, data);
  }

  uploadGpxFile(data: any, file: File) {
    const formData = new FormData();
    formData.append('gpxFile', file);
    let api_Url: string = `${this.url}/farmer/uploadGpxFile`
    return this.http.post(api_Url + '?id=' + data, formData);
  }

  getFarmData(data: any) {
    let api_Url: string = `${this.url}/farmer/getFarmMasterData`
    return this.http.post(api_Url, data);
  }

  getFarmCoordinates(data: any) {
    let api_Url: string = `${this.url}/farmer/getGpxCoordinates`
    return this.http.get(api_Url + '?farmerId=' + data);
  }

  removeFarmCoordinates(data: any) {
    let api_Url: string = `${this.url}/farmer/removeGpxCoOrdinates`
    return this.http.post(api_Url, data);
  }

  farmExtractData(data: any) {
    let api_Url: string = `${this.url}/farmer/exportFarmData?`
    return this.http.post(api_Url, data, { responseType: 'blob' });
  }

  exportCRSData(id: any) {
    let api_Url: string = `${this.url}/csr/transaction/exportAll`
    return this.http.post(api_Url + '?userId=' + id, '', { responseType: 'blob' });
  }

  //update farmer data 

  uploadFarmerDataUpdate(file: File) {
    const formData = new FormData();
    formData.append('farmerFile', file);
    let api_Url: string = `${this.url}/farmer/uploadFarmerDataForUpdate`
    return this.http.post(api_Url, formData);
  }

  uploadFarmerData(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    let api_Url: string = `${this.url}/farmer/uploadFarmerData`
    return this.http.post(api_Url, formData);
  }

  // uploadFarmData(file: File) {
  //   const formData = new FormData();
  //   formData.append('file', file);
  //   let api_Url: string = `${this.url}/farmer/uploadFarmData`
  //   return this.http.post(api_Url, formData);
  // }

  getDownloadGpxData(data: any) {
    let api_Url: string = `${this.url}/farmer/downloadAllGpx`
    return this.http.post(api_Url, data, { responseType: 'blob' });
  }

  massUploadData(files: FileList) {
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('file', files[i]);
    }
    let api_Url: string = `${this.url}/farmer/massUpload`
    return this.http.post(api_Url, formData);
  }

  // blockAssignUpload(file: File) {
  //   const formData = new FormData();
  //   formData.append('excelFile', file);
  //   let api_Url: string = `${this.url}/farmer/blockAssignUpload`
  //   return this.http.post(api_Url, formData);
  // }

  uploadFarmerBlockCategoryData(file: File, type: any) {
    const formData = new FormData();
    formData.append('farmerblockfile', file);
    let api_Url: string = `${this.url}/farmer/uploadFarmerBlockCategoryData`
    return this.http.post(api_Url + '?type=' + type, formData);
  }

  // //update farmer data 

  // uploadFarmerAddnew(file: File) {
  //   const formData = new FormData();
  //   formData.append('farmerFile', file);
  //   let api_Url: string = `${this.url}/farmer/uploadFarmerDataForUpdate`
  //   return this.http.post(api_Url, formData);
  // }

  //for map view

  getZoneforMap() {
    let api_Url: string = `${this.url}/farmer/getZonesForMap`
    return this.http.get(api_Url);
  }

  getZoneforMapNew(year: any) {
    let api_Url: string = `${this.url}/farmer/getZonesForMapNew`
    return this.http.get(api_Url + '/' + year);
  }

  getSectionbyIDforMap(id: number) {
    let api_Url: string = `${this.url}/farmer/getSectionsForMapByZone`
    return this.http.get(api_Url + '/' + id);
  }

  getMapviewPoints(id: number) {
    let api_Url: string = `${this.url}/farmer/getAllFarmGpxCoordinatesForSection`
    return this.http.get(api_Url + '/' + id);
  }

  getZoneHector(id: number) {
    let api_Url: string = `${this.url}/farmer/getHectareSumForZone`
    return this.http.get(api_Url + '/' + id);
  }

  getSectionHector(id: number) {
    let api_Url: string = `${this.url}/farmer/getHectareSumForSection`
    return this.http.get(api_Url + '/' + id);
  }

  saveAssignTask(data: any) {
    let api_Url: string = `${this.url}/farmer/saveTasks`
    return this.http.post(api_Url, data);
  }

  getListOfTasks(data: any) {
    let api_Url: string = `${this.url}/farmer/getListOfTasks`
    return this.http.post(api_Url, data);
  }

  //agri service

  gettransactionData(data: any) {
    let api_Url: string = `${this.url}/agri/getTransactionReport`
    return this.http.post(api_Url, data);
  }

  getTransactionCount(data: any) {
    let api_Url: string = `${this.url}/agri/getNoOfRecords`
    return this.http.post(api_Url, data);
  }

  getSlipDetails(data: any) {
    let api_Url: string = `${this.url}/agri/getSlipDetails`
    return this.http.post(api_Url, data);
  }

  // getSlipTransactions(data: any) {
  //   let api_Url: string = `${this.url}/agri/getSlipTransactions`
  //   return this.http.post(api_Url, data);
  // }

  getAgriDetailsMaterialWise(data: any) {
    let api_Url: string = `${this.url}/agri/getAgriDetailsMaterialWise`
    return this.http.post(api_Url, data);
  }

  saveTranactionData(data: any) {
    let api_Url: string = `${this.url}/agri/saveAgriTransaction`
    return this.http.post(api_Url, data);
  }

  extractData(body: any) {
    let api_Url: string = `${this.url}/agri/extractData`
    return this.http.post(api_Url, body, { responseType: 'blob' });
  }

  deleteTransactionbyId(idList: any) {
    let api_Url: string = `${this.url}/agri/deleteTransaction`
    return this.http.post(api_Url, idList);
  }

  getSlipDetailsBySlipNo(slipNo: any) {
    let api_Url: string = `${this.url}/agri/getSlipDetailsBySlipNo?`
    return this.http.get(api_Url + 'slipNo=' + slipNo);
  }

  removeSlipsbyId(data: any) {
    let api_Url: string = `${this.url}/agri/removeSlips`
    return this.http.post(api_Url, data);
  }
  //auth service

  getAuthToken(body: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      })
    };
    let api_Url: string = `${this.url}/auth/oauth/authenticate`
    return this.http.post(api_Url, body, httpOptions);
  }
  

  //user service

  loginAuth(body: any) {
    let api_Url: string = `${this.url}/user/login`
    return this.http.post(api_Url, body)
  }

 

  

  getUserDistrict() {
    let api_Url: string = `${this.url}/user/fieldstaff/district/getAll`
    return this.http.get(api_Url);
  }

  getUserVillage() {
    let api_Url: string = `${this.url}/user/fieldstaff/village/getAll`
    return this.http.get(api_Url);
  }

  getUserRegion() {
    let api_Url: string = `${this.url}/user/otherusers/region/getAll`
    return this.http.get(api_Url);
  }

  getUserZone(id: any) {
    let api_Url: string = `${this.url}/user/otherusers/zone/getAll?`
    return this.http.get(api_Url + 'regionid=' + id);
  }

  

  // removeUserbyID(id: number) {
  //   let api_Url: string = `${this.url}/user/userdetails/delete`
  //   return this.http.delete(api_Url + '?id=' + id);
  // }

  uploadProfilePic(data: any, file: File) {
    const formData = new FormData();
    formData.append('photo', file);
    let api_Url: string = `${this.url}/user/uploadPhoto`
    return this.http.post(api_Url + '?regid=' + data, formData);
  }

  //tracking service 

  getTrackingDetail(fromDate: any, id: any) {
    let api_Url: string = `${this.url}/tracking/tracking/getTrackingDetails?`
    return this.http.get(api_Url + 'fromDate=' + fromDate + '&fieldStaffId=' + id);
  }

  getTrackingFieldStaffList(id: any) {
    let api_Url: string = `${this.url}/tracking/tracking/getFieldStaffDetails?`
    return this.http.get(api_Url + 'zoneid=' + id);
  }

  getTrackingVillageList(year: string) {
    let api_Url: string = `${this.url}/tracking/tracking/getAllVillageList?seasonalYear=`
    return this.http.get(api_Url + year);
  }

  getAllPlanActivity(userId: any) {
    let api_Url: string = `${this.url}/tracking/tracking/getTravelPlans?userId=` + userId
    return this.http.get(api_Url);
  }

  getAllTrackingDistrict(year: string) {
    let api_Url: string = `${this.url}/tracking/tracking/getDistricts?seasonalYear=`
    return this.http.get(api_Url + year);
  }

  getSubdistrictbyID(id: any, year: any) {
    let api_Url: string = `${this.url}/tracking/tracking/getSubDistricts?`
    return this.http.get(api_Url + 'districtMasterId=' + id + '&seasonalYear=' + year)
  }

  getPlanActivityByPlanId(id: any) {
    let api_Url: string = `${this.url}/tracking/tracking/getTravelPlanByPlanId?`
    return this.http.get(api_Url + 'planId=' + id);
  }

  getFarmerbyFieldstaffID(id: any, year: any, val: any) {
    let api_Url: string = `${this.url}/tracking/tracking/getFarmerDetails?`
    return this.http.get(api_Url + 'fieldStaffId=' + id + '&farmerseason=' + year + '&blockName=' + val)
  }

  saveTrackActivity(body: any) {
    let api_Url: string = `${this.url}/tracking/tracking/saveTrackingNew`
    return this.http.post(api_Url, body)
  }

  saveTravelPlans(body: any) {
    let api_Url: string = `${this.url}/tracking/tracking/saveTravelPlans`
    return this.http.post(api_Url, body)
  }

  getTravelPlansByIdDate(body: any) {
    let api_Url: string = `${this.url}/tracking/tracking/getTravelPlansByIdDate`
    return this.http.post(api_Url, body)
  }

  deletePlanbyID(id: any) {
    let api_Url: string = `${this.url}/tracking/tracking/deletePlan?`
    return this.http.delete(api_Url + 'planId=' + id);
  }

  uploadFarmerTraining(id: any, file: any) {
    const formData = new FormData();
    formData.append('uploadFile', file);
    let api_Url: string = `${this.url}/tracking/tracking/farmerTraningExcelUpload?`
    return this.http.post(api_Url + 'userId=' + id, formData);
  }

  uploadFarmerVisit(id: any, file: any) {
    const formData = new FormData();
    formData.append('uploadFile', file);
    let api_Url: string = `${this.url}/tracking/tracking/farmerVisitExcelUpload?`
    return this.http.post(api_Url + 'userId=' + id, formData);
  }

  getTravelPlanReport(start: any, end: any, userID: any, userRole: any, zoneId: any) {
    let api_Url: string = `${this.url}/tracking/tracking/get-travelplan-report/`
    return this.http.get(api_Url + start + '/' + end + '/' + userID + '/' + zoneId + '/' + userRole, { responseType: 'blob' })
  }

  //csr

  getResourceList() {
    let api_Url: string = `${this.url}/csr/resourcegroup/getAll`
    return this.http.get(api_Url);
  }

  addResourceGroups(data: any) {
    let api_Url: string = `${this.url}/csr/resourcegroup/save`
    return this.http.post(api_Url, data)
  }

  getGroupQuestions(id: any) {
    let api_Url: string = `${this.url}/csr/questions/getByResourceGroup?`
    return this.http.get(api_Url + 'id=' + id);
  }

  addResourceQuestion(data: any) {
    let api_Url: string = `${this.url}/csr/questions/save`
    return this.http.post(api_Url, data)
  }

  getCRSTransaction(id: any) {
    let api_Url: string = `${this.url}/csr/transaction/getAll?`
    return this.http.get(api_Url + 'userId=' + id);
  }

  removeCRSTransaction(transID: any, userID: any) {
    let api_Url: string = `${this.url}/csr/transaction/deleteTransaction?`
    return this.http.delete(api_Url + 'tranId=' + transID + '&userId=' + userID);
  }

  getTransactionbyResource(resource: any, userID: any) {
    let api_Url: string = `${this.url}/csr/transaction/getByResourceGroup?`
    return this.http.get(api_Url + 'resourcegroup=' + resource + '&userId=' + userID);
  }

  saveFirstQuestion(groupID: any, quesID: any) {
    let api_Url: string = `${this.url}/csr/questions/saveFirstQuestion?`
    return this.http.get(api_Url + 'grouptypeID=' + groupID + '&resourcedetailsquestionsId=' + quesID);
  }

  //Subject

  setplanDataInfo(data: any) {
    this.planData.next(data);
  }

  getplanDataInfo() {
    return this.planData.asObservable();
  }
  sendLinkForResetPassword(email: any) {
    let api_Url: string =  this.forgetUrl + email;
    // console.log(api_Url);
    return this.http.post(api_Url, {});
  }
  changePassword(body:string){
    let api_Url: string = this.changePasswordUrl;
    return this.http.put(api_Url, body);
  }
  
  getScoreCardData() {
      let api_Url: string =  this.scoreCardUrl;   
      return this.http.get(api_Url, {observe:'response'});
  }
  getConsolidateScoreCardData(body) {
    let api_Url: string =  this.consolidateScoreCardUrl;   
    return this.http.put(api_Url, body);
  }
getYearlyScoreCardData(body) {
  let api_Url: string =  this.yearlyScoreCardUrl;   
  return this.http.put(api_Url, body);
}
getConsScoreData(body) {
  let api_Url: string =  this.consScoreUrl;   
  return this.http.put(api_Url, body);
}
getprofileViewData(body) {
  let api_Url: string =  this.profileViewUrl;   
  return this.http.put(api_Url, body);
}

}
