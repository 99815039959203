import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { LayoutComponent } from './layout/layout.component';
import { SideNavComponent } from './side-nav/side-nav.component';
import { LoaderComponent } from '../shared/loader/loader.component';

// import { MobileMenuComponent } from './mobile-menu/mobile-menu.component';

@NgModule({
  declarations: [
     HeaderComponent,
     LayoutComponent,
     SideNavComponent,
    // MobileMenuComponent,
    LoaderComponent
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [
     HeaderComponent,
     LayoutComponent,
     SideNavComponent,
     LoaderComponent
  ]
})
export class ContainerModule { }
