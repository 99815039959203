import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AppAuthGuard} from './core/AppGuard';
// import { LoginComponent } from './views/pages/user/login/login.component';

import { ForgotPasswordComponent } from './views/pages/user/forgot-password/forgot-password.component';
import { LayoutComponent } from './views/container/layout/layout.component';
import { ChangePasswordComponent } from './views/pages/user/change-password/change-password.component';
import { ErrorHandlingComponent } from './views/pages/error-handling/error-handling.component';


const routes: Routes = [
  //{ path: 'forgot-password', component: ForgotPasswordComponent },
  
  {
    path: '',
    redirectTo: 'admin',
    pathMatch: 'full',
  },
  
  
  { path: 'change-password', component: ChangePasswordComponent },
  { path: '404', component: ErrorHandlingComponent},
  {
    path: 'forgot-password',
    loadChildren: () => import('./views/pages/user/user.module').then(m => m.UserModule)
  },
  {
    path: 'admin', component: LayoutComponent, canActivate: [AppAuthGuard],
    loadChildren: () => import('./views/pages/admin/admin.module').then(m => m.AdminModule)
  },
  // {
  //   path: 'dashboard', component: LayoutComponent, canActivate: [AppAuthGuard],
  //   loadChildren: () => import('./views/pages/dashboard/dashboard.module').then(m => m.DashboardModule)
  // },

  {path: '**', redirectTo: '/404'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}