import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/services/data.service';
import { LoaderService } from 'src/app/services/LoaderSevice';
import { ModalPopupComponent } from 'src/app/views/shared/modal-popup/modal-popup.component';
declare function googleTranslateElementInit(): void;

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  public error: string = '';
  public email: string = '';
  changePasswordForm: FormGroup;

  constructor(private dataservice: DataService, private modalService: NgbModal, private loaderService: LoaderService) { }

  ngOnInit(): void {
    this.changePasswordForm = new FormGroup({
      password: new FormControl(),
      confirmPassword: new FormControl()
    });
  }
  ngAfterViewChecked() {
    console.log("translator");
    try {
      googleTranslateElementInit();
      //this._cd.detectChanges();
    } catch (e) {
      console.log(e, "google translator erro");
    }
  }
  changePassword() {
    const url = window.location.href;
    let token;
    if (url.includes('?')) {
      const httpParams = new HttpParams({ fromString: url.split('?')[1] });
      token = httpParams.get('token');
    }
    let password = this.changePasswordForm.value.password;
    let confirmPassword = this.changePasswordForm.value.confirmPassword;
    if (password !== confirmPassword) {
      this.openModal('error', "Error", "The New Password and Confirm Password Does Not Match");
    }
    else {
      if (this.changePasswordForm.valid) {
        if (this.validatePwd(password) && this.validatePwd(confirmPassword)) {
          let body: any = { token: token, newPassword: password };
          this.loaderService.isLoading.next(true);
          this.dataservice.changePassword(body).subscribe((res: any) => {
            if (res?.message?.toLowerCase()?.includes('success')) {
              this.openModal('success', "Success", "Your Password has been reset successfully");
            } else {
              this.openModal('error', "Error", res.message);
            }
            this.loaderService.isLoading.next(false);
          }, err => {
            this.loaderService.isLoading.next(false);
            // this.openModal('error', "Error", "Something went wrong! Please try again.");
            this.openModal('error', "Error", err?.message);
          });

        } else {
          let msg = '<p class="text-left"><u>'+'Invalid format, Password should contain:'+'</u><br/>'+'1. Password length should be 8 or more.' + '<br/>' + '2. At least one upper case letter (A-Z).' + '<br/>' + '3. At least one lower case letter' +'<span class="text-lowercase"> (a-z)</span>.' + '<br/>' + '4. At least one number (0-9).' + '<br/>' + '5. At least one symbol allowed (-().&!@?#,/”+' + "').</p>";
          this.openModal('error', "Error", msg);
        }
      }
      else {
        this.openModal('error', "Error", "Required fields can not be blank");
      }
    }
  }
  
  openModal(type, msg1, msg2) {
    const modalRef = this.modalService.open(ModalPopupComponent);
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.message1 = msg1;
    modalRef.componentInstance.message2 = msg2;
  }

  validatePwd(val) {    
    let re = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[-().&!@?'#,/”+])[A-Za-z\d-().&!@?'#,/”+]{8,}$/;
    return re.test(val); 
  }

}
