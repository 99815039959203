import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { LoaderService } from 'src/app/services/LoaderSevice';
import * as $ from 'jquery';



@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  // DECLARATION AND INITIALIZATION
  public isMobileView: boolean = false;
  list = [];
  showCookieSettings = true;
  isShowEdit:boolean = false;

  constructor(private router: Router,  private dataService: DataService,private loaderService:LoaderService){
    this.list = ['SEZ Wellbeing'];
    router.events.subscribe((event: NavigationEnd) => {
      if (event.urlAfterRedirects) {
        if (event.urlAfterRedirects === '/admin/assessment-parameter') {
          this.loaderService.currentMessage.subscribe(res => {            
            this.isShowEdit = res;
            this.getRoute(event.urlAfterRedirects)
          })
        } else {
          this.getRoute(event.urlAfterRedirects);
          if(event.urlAfterRedirects.includes('change-password')|| event.urlAfterRedirects.includes('forgot-password')){
            this.router.navigate(['404']);
          }
        }
      }
    });     
  }

  ngOnInit() {
    this.dataService.getRole(localStorage.getItem('email')).subscribe((res:any) => {
      localStorage.setItem('role', res.userRole);
      if(res.userRole == 'Investor Admin'){
        this.list[1] = 'Admin';
      } else if(res.userRole == 'Investor User'){
        this.list[1] = 'User'
      } else{
        this.list[1] = res.userRole;
      }
      localStorage.setItem('company', res.companyName);
      localStorage.setItem('name', res.firstName+res.lastName);
    });
    this.addBanner();
  

    // GET USER ROLE BY LOGGED IN USER
    // this.list = ['SEZ Wellbeing', 'Admin']
    let pathName = location.hash;    
    this.getRoute(pathName);   
    if (pathName.includes('change-password') || pathName.includes('forgot-password')) {
      this.router.navigate(['404']);
    }  
  }

  getRoute(pathName){    
    if(pathName === '/admin/user' || pathName.includes('/admin/user')){ //&& !this.list.includes('User Management')
      this.list[2] = 'User Management';
      if(this.list[3]) this.list.splice(3,1);
    } else if(pathName === '/admin/master-data/data-list'){  //&& !this.list.includes('Master Data Management')
      this.list[2] = 'Master Data Management';
      if(this.list[3]) this.list.splice(3,1);
    }
    else if(pathName === '/admin/master-data/configuration'){  //&& !this.list.includes('Configuration')
      this.list[2] = 'Configuration';
      if(this.list[3]) this.list.splice(3,1);
    }
    else if(pathName === '/admin/monthly-data/data-list'){  //&& !this.list.includes('Monthly Data Management')
      this.list[2] = 'Monthly Data Management';
      if(this.list[3]) this.list.splice(3,1);
    }
    else if(pathName === '/admin/assessment-parameter'){
      this.list[2] = 'Assessment Parameter';      
      if(this.isShowEdit) this.list[3] = 'Edit';
      if(this.list[3] && !this.isShowEdit) this.list.splice(3,1);
    }
    else if(pathName === '/admin/score-card'){
      this.list[2] = 'Score Card';            
      if(this.list[3]) this.list.splice(3,1);
    }
    else if(pathName === '/admin/dashboard'){
      this.list[2] = 'Dashboard';            
      if(this.list[3]) this.list.splice(3,1);
    }
    else if(pathName === '/admin/dashboard/trends-view'){
      this.list[2] = 'Trend view';            
      if(this.list[3]) this.list.splice(3,1);
    }
    else if(pathName.includes('/add') && !this.list.includes('Add') && !pathName.includes('/view') && !pathName.includes('/edit')){
      // this.list[2] = pathName.includes('/admin/monthly-data/') ? 'Monthly Data Management': 'Master Data Management';
      this.list[2] = this.showPathText(pathName);
      this.list[3] = 'Add'
    }
    else if(pathName.includes('/view') && !this.list.includes('View') && pathName.includes('/view')){
      // this.list[2] = pathName.includes('/admin/monthly-data/') ? 'Monthly Data Management':'Master Data Management';
      this.list[2] = this.showPathText(pathName);
      this.list[3] = 'View'
    }
    else if(pathName.includes('/edit') && !this.list.includes('Edit') && pathName.includes('/edit')){
      // this.list[2] = pathName.includes('/admin/monthly-data/') ? 'Monthly Data Management' : 'Master Data Management';
      this.list[2] = this.showPathText(pathName);
      this.list[3] = 'Edit'
    }
  }

  addBanner(){
    //if(this.appSharedService.secureStorage.getItem('display_name')){
      let emailDomain = window.getComputedStyle(document.documentElement).getPropertyValue('--email-domain');
     // let userMail = this.appSharedService.secureStorage.getItem('display_name');
        //if (userMail.indexOf(emailDomain) === -1) {
          var s = document.createElement( 'script' );
          s.setAttribute( 'src', 'https://cdn-apac.onetrust.com/scripttemplates/otSDKStub.js' );
          s.setAttribute( 'data-domain-script', '0f80827c-5887-4d97-acc6-f155ae5fb7e7-test' );
          s.setAttribute( 'type', 'text/javascript' );
          s.setAttribute( 'charset', 'UTF-8' );
          document.body.appendChild( s );
          this.showCookieSettings= true;
        // }
        // else{
        //   this.showCookieSettings= false;
        // }
    // }
    // else{
    //   window.setTimeout(()=>{this.addBanner()},1000);
    // }
  }

  showPathText(pathName) {
    if (pathName.includes('/admin/monthly-data/')) {
      return 'Monthly Data Management';
    } else if (pathName.includes('/admin/assessment-parameter')) {
      return 'Assessment Parameter';
    } else {
      return 'Master Data Management';
    }
  }

  toggelMobileView() {
    this.isMobileView = this.isMobileView ? false : true

    if (this.isMobileView) {
      $(".navMenu").css({
        'width': '7%'
      });
      $(".navMenu").addClass('mobile_sidebar');
      $(".menuList .logo").hide();
      $(".menuList > ul li .menuName").hide();

      $(".mainContainer").css({
        'width': '93%'
      });

      // $(".toggle_icon").css({
      //   'left': '3.8%'
      // });

      // $(".env-notification").css({
      //   'left': '6%'
      // });


    } else {
      $(".navMenu").css({
        'width': '20%'
      });
      $(".navMenu").removeClass('mobile_sidebar');
      $(".menuList .logo").show();
      $(".menuList > ul li .menuName").show();
      $(".mainContainer").css({
        'width': '80%'
      });

      // $(".toggle_icon").css({
      //   'left': '-14px'
      // });

      // $(".env-notification").css({
      //   'left': '22%'
      // });
    }
  }

  

}
