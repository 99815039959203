import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
// import { AuthAdService } from '../../api/auth-ad/auth-ad.service';
// import { SideNavMenus } from './side-nav.const';
// import { Subscription } from 'rxjs/Subscription';
// import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
// import { ConfirmModalService } from 'src/app/shared/confirm-modal/confirm-modal.service';
// import { ConfirmModalComponent } from 'src/app/shared/confirm-modal/confirm-modal.component';
// import { ApiUserService } from 'src/app/api/user/api-user.service';
// import { AuthUser } from 'src/app/api/auth-ad/data/auth-user';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})



export class SideNavComponent implements OnInit {
  list = [];
  activeClass = '';
  activeClass1 = '';
  monthlyMenu = 'blank';
  masterMenu = 'blank';

  constructor(private router: Router, private dataService: DataService){
    router.events.subscribe((event: NavigationEnd) => {
      if(event.url){
        this.getRoute(event.url)
        if(event.url.includes('admin/monthly-data/view') || event.url.includes('admin/monthly-data/edit') || event.url.includes('/admin/monthly-data/data-list') || event.url.includes('/admin/monthly-data/upload') || event.url.includes('/admin/monthly-data/esgupload') || event.url.includes('/admin/monthly-data/esgUser')){
          // this.monthlyMenu = 'active'
        } else{
          localStorage.removeItem('selectedYear');
          localStorage.removeItem('selectedMonth');
          localStorage.removeItem('selectedStatus');
        }
        if(event.url.includes('/admin/master-data/data-list') || event.url.includes('admin/master-data/add/')){
          // this.monthlyMenu = 'active'
        } else{
          localStorage.removeItem('selectedSector');
          localStorage.removeItem('selectedSubSector');
          localStorage.removeItem('selectedMasterStatus');
        }
      }
    });
  
  }

  menuShow = 'hide';
  menuShowMonthly = 'hide';
  role: string;
  name: string;


  // DECLARATION AND INITIALIZATION
  // otherMenus = SideNavMenus.otherMenus;

  // menuList: any = [];
  // confirmModalSubscription: Subscription;
  // confirmModalRef: BsModalRef;
  // loggedInUser: AuthUser;
  // trainingVideoLink: string = '';


  ngOnInit() {
    this.dataService.getRole(localStorage.getItem('email')).subscribe((res:any) => {
      this.role  =res.userRole
      
      localStorage.setItem('role', res.userRole);
      localStorage.setItem('company', res.companyName);
      localStorage.setItem('name', res.firstName+res.lastName);
      
    });

   

   

    // INITAILIZE MENU LIST
    // let toSetMenus = [
    //   {
    //     menuName: 'Main Menu',
    //     children: []
    //   },
    //   {
    //     menuName: 'Other',
    //     children: this.otherMenus
    //   }
    // ];
    // this.loggedInUser = this.authAdService.getUser();
    // const roleId = this.authAdService.getUser().roleId;
    // if (roleId === 3) {
    //   this.trainingVideoLink = 'https://olam.sharepoint.com/sites/IAF/TrainingVideo/Validator/IAF_Validator.mp4'
    // } else if (roleId === 4) {
    //   this.trainingVideoLink = 'https://olam.sharepoint.com/sites/IAF/TrainingVideo/ExecutiveView/IAF_Executive.mp4'
    // }
    // // GET MENULIST BY ROLEID
    // //toSetMenus[0].children = SideNavMenus.getMenusByRole(roleId);
    // this.menuList = SideNavMenus.getMenusByRole(roleId);;
  }

  getRoute(pathName){
    this.masterMenu = 'inactive';
    if(pathName === '/admin/master-data/data-list' || pathName === '/admin/master-data/configuration' ){
      this.activeClass = 'active'
      this.activeClass1 = ''
      this.menuShowMonthly = 'hide'
      this.monthlyMenu = 'inactive'
    }
    else if(pathName === '/admin/monthly-data/data-list' || pathName === '/admin/monthly-data/lockUnlock' || pathName === '/admin/monthly-data/yearlyData'){
      this.activeClass1 = 'active'
      this.activeClass = ''
      this.menuShow = 'hide';
      this.monthlyMenu = 'active'
    }
    else if(pathName === '/admin/dashboard' || pathName === '/admin/score-card' || pathName === '/admin/assessment-parameter' || pathName === '/admin/user'){
      this.activeClass1 = 'noShow'
      this.activeClass = ''
      this.menuShowMonthly = 'hide'
      this.menuShow = 'hide'
      this.monthlyMenu = 'inactive'
    }
    else{
      this.activeClass = '';
      this.activeClass1 = '';
    }
    if(pathName.includes('admin/monthly-data/view') || pathName.includes('admin/monthly-data/edit') || pathName.includes('/admin/monthly-data/data-list')){
      this.monthlyMenu = 'active';
      this.masterMenu = 'inactive';
    }
    else if(pathName.includes('/admin/master-data/data-list') || pathName.includes('admin/master-data/add/'))
    {
      this.masterMenu = 'active';
      this.activeClass = 'active';
      this.monthlyMenu = 'inactive';
    }
    else{
      this.monthlyMenu = 'inactive'
      this.masterMenu = 'inactive';
    }
  }
  // monthDataBtn(){
  //   this.router.navigate(['../admin/monthly-data/data-list']);
  // }
  

  showMenu(){
    if(this.menuShow === 'show'){
     this.menuShow = 'hide'
    }
    else if(this.menuShow === 'hide'){
      this.menuShow = 'show'
     }
  }
  showMenuMonthly(){
    if(this.menuShowMonthly === 'show'){
     this.menuShowMonthly = 'hide'
    }
    else if(this.menuShowMonthly === 'hide'){
      this.menuShowMonthly = 'show'
     }
  }

  /**
   * METHOD FOR LOGOUT
   */
  // onLogOut() {
  //   let initialState = {
  //     title: 'Log Out Confirmation',
  //     content: 'Are you sure you want to logout?',
  //     btnText: 'Yes',
  //     btnNoText: 'No',
  //     deleteConfirmationText: 'signOut'
  //   };

  //   this.confirmModalPopUp(initialState, (confirm) => {
  //     if (confirm && confirm == initialState.deleteConfirmationText) {
  //       let token = this.authAdService.getUser().accessToken;
  //       this.apiUserService.logout(token).subscribe(response => {
  //         this.authAdService.logout();
  //       }, error => {
  //         console.log(error);
  //       })
  //     }
  //   })
  // }

  /**
   * METHOD FOR CONFIRM MODAL CALBACK
   * @param initialState 
   * @param callback 
   */
  // confirmModalPopUp(initialState, callback: any) {
  //   if (this.confirmModalSubscription) {
  //     this.confirmModalSubscription.unsubscribe();
  //     this.confirmModalService.modalStatus.next(null);
  //   }
  //   this.confirmModalService.modalStatus.next(null);
  //   this.confirmModalRef = this.modalService.show(ConfirmModalComponent, { class: ' modal-dialog-centered confirmModal', backdrop: 'static', initialState });
  //   this.confirmModalSubscription = this.confirmModalService.modalStatus.subscribe(modalStatus => {
  //     // CHECK MODAL STATUS CONFIRM/CANCEL
  //     if (modalStatus) {
  //       callback(modalStatus)
  //       this.confirmModalRef.hide();
  //     } else if (modalStatus == false) {
  //       callback(modalStatus)
  //     }
  //   })
  // }

  // ngOnDestroy() {
  //   if (this.confirmModalSubscription) {
  //     this.confirmModalSubscription.unsubscribe();
  //     this.confirmModalService.modalStatus.next(null);
  //   }
  // }

}
