import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-popup',
  templateUrl: './modal-popup.component.html',
  styleUrls: ['./modal-popup.component.scss']
})
export class ModalPopupComponent implements OnInit {
  @Input() message1;
  @Input() type;
  @Input() message2;

  constructor(public activeModal: NgbActiveModal,private route:Router) {}
  

  ngOnInit(): void {
  }

  navigateToLogin(){
    const url = window.location.href;
    let RedirectUrl = url?.split("#");
    document.location.href = RedirectUrl[0];
    this.activeModal.close(true);
  }

}
