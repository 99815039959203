<!-- PAGE LAYOUT START -->
<div class="pageWrapper">
    <!-- PAGE HEADER -->
    <div class="header">
        <app-header></app-header>
    </div>
    <!-- SIDE MENU -->
    
    <div class="navMenu">
        <div class="toggle_icon" (click)=toggelMobileView()>
            <i [ngClass]="isMobileView?'fa fa-angle-right':'fa fa-angle-left'"></i>
        </div>
        <app-side-nav></app-side-nav>
    </div>
    <!-- PAGE CONTENT -->
    <div class="mainContainer">
        <!-- <div class="toggle_icon"><i class="fa fa-angle-left"></i></div> -->
        
        <nav aria-label="breadcrumb">
            <ul class="breadcrumb">
                <li class="breadcrumb-item" *ngFor="let x of list">{{x}}</li>
                <!-- <li class="breadcrumb-item">GSEZ Admin</li>
                <li class="breadcrumb-item active" aria-current="page">
                    User Management</li> -->
            </ul>
        </nav>
        <!-- <h3 class="display-sm-block mobileRole">Admin</h3> -->
        <router-outlet></router-outlet>
        <!-- <footer class="footer" >

          
              
          
              <a class="ofis-privacy-policy-link" [ngClass]="{'no-border':!showCookieSettings}" href="https://www.olamgroup.com/cookies.html" target="_blank">
                Cookie Policy
              </a>
              
          
        
                <button id="ot-sdk-btn" class="ot-sdk-show-settings cookie_btn" *ngIf="showCookieSettings">Cookie Settings</button>
     
          

          
          </footer> -->
    </div>
    <!-- MOBILE MENU -->
    <div class="mobileNavMenu">
        <!-- <app-mobile-menu></app-mobile-menu> -->
    </div>
    <!-- <app-loader></app-loader> -->
</div>