<!-- MENU START -->
<nav>
    <div class="menuList">
        <!-- SIDE MENU LOGO -->
        <div class="logo text-center">
            <img src="assets/images/sez-logo.svg" width="130" />
        </div>
        <div class="logo-xs text-center d-sm-none">
            <img src="assets/images/sez-logo.svg" />
        </div>
        <!-- MENU LIST -->

            <!-- <h3 class="menuTitle">{{menu.menuName}}</h3> -->
            <ul class="sub_childern_ul">
                <li>
                    <a routerLink="../admin/dashboard" routerLinkActive="active"  [routerLinkActiveOptions]="{ exact: true }">
                        <div class="menuIconWrap">
                            <span class="menuIcon dashIcon pull-left"></span>
                            <span class="menuName ">Dashboard</span>
                        </div>
                    </a>
                </li>
                <li *ngIf="role == 'GSEZ Admin' || role == 'SWC Admin' || role == 'Investor User' || role == 'Investor Admin'">
                    <a routerLink="../admin/score-card" routerLinkActive="active">
                        <div class="menuIconWrap">
                            <span class="menuIcon scoreIcon pull-left"></span>
                            <span class="menuName">Score Card</span>
                        </div>
                    </a>
                </li>
                <li *ngIf="role == 'GSEZ Admin' || role == 'SWC Admin'">
                    <a routerLink="../admin/assessment-parameter" routerLinkActive="active">
                        <div class="menuIconWrap">
                            <span class="menuIcon assessIcon pull-left"></span>
                            <span class="menuName">Assessment Parameter</span>
                        </div>
                    </a>
                </li>
                <!-- <li>

                    <a routerLink="../admin/monthly-data/esgUser" routerLinkActive="active">

                            <span class="menuIcon assessIcon pull-left"></span>

                            <span class="menuName">ESG User</span>

                    </a>

                </li> -->
                <li  *ngIf="role !== 'GSEZ User' && role !== 'SWC User' && role !== 'ESG User'" class="nav-group" [ngClass]="menuShow == 'show' ? 'show' : 'hide'">
                    <a class="nav-link nav-dropdown-toggle  {{activeClass}}" (click)="showMenu()">
                            <span class="menuIcon masterIcon pull-left"></span>
                            <span class="menuName">Master Data Management</span>
                            <i class="fas fa-chevron-down align-right"></i>
                    </a>
                    <ul class="dropdown">
                        <li><a routerLink="../admin/master-data/data-list" class=" {{masterMenu}}">Master Data</a></li>
                        <li *ngIf="role === 'GSEZ Admin' || role === 'SWC Admin'"><a routerLink="../admin/master-data/configuration" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Configuration</a></li>
                    </ul>
                </li>
                <li class="nav-group" [ngClass]="menuShowMonthly == 'show' ? 'show' : 'hide'">
                    <a class="nav-link nav-dropdown-toggle  {{activeClass1}}" (click)="showMenuMonthly()" >
                        <!-- <div class="menuIconWrap"> -->
                            <span class="menuIcon monthlyIcon pull-left"></span>
                            <span class="menuName">Monthly Data Management</span>
                            <!-- d-none d-md-block -->
                            <i class="fas fa-chevron-down align-right"></i>
                        <!-- </div> -->
                    </a>
                    <ul class="dropdown">
                        <li class="monthlyB"><a routerLink="../admin/monthly-data/data-list" routerLinkActive="active">Monthly Data</a></li>
                        <!-- <li class="monthly"><a (click)=monthDataBtn() class=" {{activeC}}">Monthly Data</a></li> -->
                        <li *ngIf="role === 'GSEZ Admin' || role === 'SWC Admin'"><a routerLink="../admin/monthly-data/lockUnlock" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Lock / Unlock</a></li>
                        <li *ngIf="role === 'GSEZ Admin' || role === 'SWC Admin' || role === 'Investor Admin' || role === 'Investor User'" ><a routerLink="../admin/monthly-data/yearlyData" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Yearly Data</a></li>
                    </ul>
                </li>
                <li *ngIf="role !== 'Investor User' && role !== 'ESG User' && role !== 'GSEZ User' && role !== 'SWC User'" >
                    <a routerLink="../admin/user" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                        <div class="menuIconWrap">
                            <span class="menuIcon userIcon1"></span>
                            <span class="menuName">User Management</span>
                        </div>
                    </a>
                </li>
            </ul>
    </div>
</nav>
<!-- MENU END -->