// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
 const zuulHostName = 'https://gateway.siwp-nkok.com';
export const environment = {

  userServiceApi: zuulHostName + '/siwpuserauth/api/v1/user',
  masterServiceApi: zuulHostName + '/siwpmaster/api/v1/master',
  monthlyServiceApi: zuulHostName + '/siwpmonthly/api/v1/monthly',
  storageServiceApi: zuulHostName + '/siwpstorageconnector/api/v1/storageconnect/imageUpload',
  forgetPasswordApi:zuulHostName + '/siwpuserauth/forget/api',

  defaultApi: zuulHostName,

  production: true,
  keycloakConfig: {
    //use below clienId to run in local
    // clientId: 'siwp-portal-local',
    // realm: 'siwp-dev',
    // url: 'https://digitalauthdev.olamnet.com/auth'
    

    //use below clienId to run in Dev
    // clientId: 'siwp-portal',
    // realm: 'siwp-sit',
    // url: 'https://digitalauthdev.olamnet.com/auth'

        //use below clienId to run in UAT
        clientId: 'siwp-portal',
        realm: 'siwp',
        url: 'https://siwpiam.siwp-nkok.com/auth/'

  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.