import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/authentication/Auth.Service';
import { DataService } from 'src/app/services/data.service';
// import { AuthAdService } from '../../api/auth-ad/auth-ad.service';
// import { AuthUser } from '../../api/auth-ad/data/auth-user';
// import { UserRoles } from '../user-roles.const';
// import { Subscription } from 'rxjs/Subscription';
// import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
// import { ConfirmModalService } from 'src/app/shared/confirm-modal/confirm-modal.service';
// import { ConfirmModalComponent } from 'src/app/shared/confirm-modal/confirm-modal.component';
 // declare var $: any;
declare function googleTranslateElementInit(): void;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
  // DECLARATION
  // activeHeader = 'home';
  // loggedInUser: AuthUser;
  // confirmModalSubscription: Subscription;
  // confirmModalRef: BsModalRef;
  role: string;
  name: string;
  // public currentBaseURL:any;
  // public loginEnvNotification:any;


  constructor(private router: Router, private auth: AuthenticationService, private dataService: DataService) { }

  ngOnInit() {
    //googleTranslateElementInit();
    this.dataService.getRole(localStorage.getItem('email')).subscribe((res:any) => {
      if(res.userRole == 'Investor Admin'){
        this.role = 'Admin';
      } else if(res.userRole == 'Investor User'){
        this.role = 'User'
      } else{
        this.role = res.userRole;
      }
      this.name  = res.firstName+res.lastName
      localStorage.setItem('role', res.userRole);
      localStorage.setItem('company', res.companyName);
      localStorage.setItem('name', res.firstName+res.lastName);
    });
    

    // this.getActivePath();
    // this.loggedInUser = this.authAdService.getUser();
    // // console.info('loggedInUser', this.loggedInUser)
    // this.loggedInUser.roleName = UserRoles.roleNames[this.loggedInUser.roleId];
    // this.currentBaseURL = window.location.origin;

    // if(this.currentBaseURL == 'http://localhost:4200'){
    //   this.loginEnvNotification = "AZURE DEV – OLD INSTANCE"
    // }
    // else if(this.currentBaseURL == 'https://iaf-portal-dev.olamnet.com'){
    //   this.loginEnvNotification = "AZURE DEV – OLD INSTANCE"
    // }
    // else if(this.currentBaseURL == 'https://iaf-portal-sit.olamnet.com'){
    //   this.loginEnvNotification = "AZURE SIT – OLD INSTANCE"
    // }
    // else if(this.currentBaseURL == 'https://iaf-portal-uat.olamnet.com'){
    //   this.loginEnvNotification = "AZURE UAT – OLD INSTANCE"
    // }
    // else if(this.currentBaseURL == 'https://iaf-portal-azure.olamnet.com'){
    //   this.loginEnvNotification = "AZURE PROD – OLD INSTANCE"
    // }
  }
  ngAfterViewChecked() {
    try {
      googleTranslateElementInit();
      //this._cd.detectChanges();
    } catch (e) {
      console.log(e, "google translator erro");
    }
  }

  // getActivePath() {
  //   this.router.events.subscribe(res => {
  //     this.activeHeader = this.router.url.replace(/^\/|\/$/g, '');
  //   });
  // }

  // enableActiveHeader(header) {
  //   this.activeHeader = header;
  // }

  /**
   * METHOD FOR LOGOUT
   */
  // onLogOut() {
  //   let initialState = {
  //     title: 'Sign Out Confirmation',
  //     content: 'Are you sure you want to sign out?',
  //     btnText: 'Yes',
  //     btnNoText: 'No',
  //     deleteConfirmationText: 'signOut'
  //   };

  //   this.confirmModalPopUp(initialState, (confirm) => {
  //     if (confirm && confirm == initialState.deleteConfirmationText) {
  //       this.authAdService.logout();
  //     }
  //   })
  // }

  /**
   * METHOD FOR CONFIRM MODAL CALBACK
   * @param initialState 
   * @param callback 
   */
  // confirmModalPopUp(initialState, callback: any) {
  //   if (this.confirmModalSubscription) {
  //     this.confirmModalSubscription.unsubscribe();
  //     this.confirmModalService.modalStatus.next(null);
  //   }
  //   this.confirmModalService.modalStatus.next(null);
  //   this.confirmModalRef = this.modalService.show(ConfirmModalComponent, { class: ' modal-dialog-centered confirmModal', backdrop: 'static', initialState });
  //   this.confirmModalSubscription = this.confirmModalService.modalStatus.subscribe(modalStatus => {
  //     // CHECK MODAL STATUS CONFIRM/CANCEL
  //     if (modalStatus) {
  //       callback(modalStatus)
  //       this.confirmModalRef.hide();
  //     } else if (modalStatus == false) {
  //       callback(modalStatus)
  //     }
  //   })
  // }


  logout() {
    localStorage.removeItem('role');
    localStorage.removeItem('company');
    localStorage.removeItem('email');
    this.auth.logout();
  }

}
