<!-- HEADER START -->
<header class="">
  <!-- <div class="env-notification">
    <i class="fa fa-warning"></i> ff
  </div> -->
  <!-- LOGO -->
  <div class="mobileLogo">
    <img src="../../../../assets/images/sez-logo.svg">
  </div>
  <!-- HEADER RIGHT -->
  <div class="headerRight">
    <ul>
      <li>
        <!-- <span class="flag-icon"></span>-->
        <!-- <span id="{{id}}" ></span>  -->
        <!-- (click)="hideNavigationInMobile()" -->
        <div class="translate">
          <span id="google_translate_element"></span>
        </div>
      </li> 
      <li class="userName">
        <a dropdownToggle>
          <div>
            <label class="notranslate">{{name}} | {{role}} | <a class="logoutRole" (click)="logout()">Logout</a></label>
            <!-- <span>GSEZ Admin</span> -->
            
          </div>
        </a>
      </li>
      <li class="olamLogo">
        <img src="assets/images/logo1.png" alt="logo" class="logoSize" />
        <img src="assets/images/gsez.svg" alt="GSEZ logo" />
      </li>
      <li class="logoutIcon"><img src="../../../../assets/images/icons/logout.svg"></li>
    </ul>
  </div>
</header>
<!-- <div class="mobile-env-notification">
  {{loginEnvNotification}}
</div> -->
<!-- HEADER END -->
