<div class="d-flex flex-column flex-root">
    <!--begin::Login-->
    <div class="login login-2 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white ft-Password" id="kt_login">
        <!--begin::Aside-->
        <div class="cLanguage">
            <!-- <select name="" id="">
                <option value="">Select Language</option>
            </select> -->
            <span id="google_translate_element"></span>
        </div>
        <div class="login-aside order-1 order-lg-1 d-flex flex-row-auto position-relative overflow-hidden">
            <!--begin: Aside Container-->
            <div class="d-flex flex-column-fluid flex-column justify-content-between py-9 px-7 py-lg-13 px-lg-35 fPassword">
                <!--begin::Logo-->
                <a href="#" class="text-center pt-2">
                    <img src="assets/images/logo1.png" class="max-h-75px" alt="" />
                    <img src="assets/images/logo2.png" class="max-h-75px" alt="" />
                </a>
                <!--end::Logo-->
                <!--begin::Aside body-->
                <div class="d-flex flex-column-fluid flex-column flex-center">
                    <!--begin::Forgot-->
                    <div class="login-form login-forgot pt-11">
                        <!--begin::Form-->
                        <form class="form" novalidate="novalidate" id="kt_login_forgot_form">
                            <!--begin::Title-->
                            <div class="text-center pb-8">
                                <h2 class="font-weight-bolder text-dark font-size-h2 font-size-h1-lg">Forgot Password</h2>
                                <!-- <p class="text-muted font-weight-bold font-size-h4">Enter your email to reset your password</p> -->
                            </div>
                            <!--end::Title-->
                            <!--begin::Form group-->
                            <!-- <span [(ngClass)]='cls'>{{msg}}</span> -->
                            <div class="form-group">
                                <label class="font-size-h6 text-dark pt-5">Email</label>
                                <input [(ngModel)]="email" class="form-control h-auto py-4 px-6 rounded-md" type="email" placeholder="Email Address" name="email" autocomplete="off" (input)="validateEmail($event.target.value)"/>
                                <div *ngIf="submitted && email ==''" class="errorMsg">Email Required</div>
                                <div *ngIf="submitted && isInvalidEmail" class="errorMsg">Email must be a valid email address</div>
                            </div>
                            <!--end::Form group-->
                            <!--begin::Form group-->
                            <div class="form-group d-flex flex-wrap flex-center pb-lg-0 pb-3">
                                <button type="button" id="kt_login_forgot_submit" (click)="sendLinkForResetPassword()" class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 w-100">Send Link</button>
                                
                            </div>
                            <!--end::Form group-->
                        </form>
                        <!--end::Form-->
                    </div>
                    <!--end::Forgot-->
                </div>
                <!--end::Aside body-->
                <!--begin: Aside footer for desktop-->
                
                <!--end: Aside footer for desktop-->
            </div>
            <!--end: Aside Container-->
        </div>
        <!--begin::Aside-->
        <!--begin::Content-->
        
        <!--end::Content-->
    </div>
    <!--end::Login-->
</div>
