
  <div class="modal-body Success">
    <button type="button" class="close pull-right" aria-label="Close" (click)="activeModal.close('Close click')">
      <span aria-hidden="true">×</span>
    </button>
    <div class="modalSuccess">
    <div *ngIf="type == 'success'" class="successColor">
        <div class="icon">
          <i class="far fa-check-circle"></i>
        </div>
    </div>
    <div *ngIf="type == 'error'" class="errorColor">
      <div class="icon">
        <i class="far fa-times-circle"></i>
      </div>
    </div>
    <div *ngIf="type == 'update'" class="updateColor">
      <div class="icon">
        <i class="far fa-check-circle"></i>
      </div>
    </div>
    <div *ngIf="type == 'status'" class="updateColor">
      <div class="icon">
        <i class="far fa-exclamation-triangle"></i>
      </div>
    </div>
  </div>

    <p class="headingSuccess"> {{message1}}</p>
    <p class="subText" [innerHtml]="message2"></p>
    <div *ngIf="type == 'status'" class="d-flex justify-content-center" >
      <button class="btn btn customButton noIcon mr-2" (click)="activeModal.close(true)">Confirm</button>
      <button class="btn btn customButton btn-grey noIcon" (click)="activeModal.close(false)">Cancel</button>      
    </div>
    <div class="d-flex justify-content-center" *ngIf="message2?.includes('Your Password has been reset successfully') || message2?.includes('Email Sent Successfully')">
      <button class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 w-100" (click)="navigateToLogin()" style="border-radius: 6px;">BACK TO LOGIN</button>
    </div>
    

  </div>
  <!-- <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
  </div> -->