import { KeycloakService } from 'keycloak-angular';
import { environment } from 'src/environments/environment';
export class ValidateUrl{
    static setURL(): string {
        //debugger;
        if(!localStorage.getItem('logout') || localStorage.getItem('logout') === 'true'){
            localStorage.setItem('logout','false')
            return window.location.origin;
        }
        return window.location.href;
    }
}
export function initializeKeycloak(keycloak: KeycloakService): () => Promise<any> {

    if(document.URL.includes('/change-password') || document.URL.includes('/viewnews/') || (document.URL.includes('/forgot-password') && localStorage.getItem('forgotPasswordSuccess') != 'true')
    || document.URL.includes('/legal/privacy-policy') || document.URL.includes('/legal/terms-and-condition') || document.URL.includes('/legal/osc')){
      return () => keycloak.init({
        config: environment.keycloakConfig,
        initOptions: {
          // onLoad: 'login-required',
          checkLoginIframe: true,
          checkLoginIframeInterval: 60,
        },
        bearerExcludedUrls: ['/*'],
  
        loadUserProfileAtStartUp: false,
      });
    } else{

        // return () => keycloak.init({
        //     config: environment.keycloakConfig,
        //     initOptions: {
        //         onLoad: 'login-required',
        //         checkLoginIframe: true,
        //         redirectUri: ValidateUrl.setURL(),
        //     },
        //     bearerExcludedUrls: ['/unsecure'],
        //     loadUserProfileAtStartUp: true,
        //     enableBearerInterceptor: true
        // })
    

    return () => keycloak.init({
      config: environment.keycloakConfig,
      initOptions: {
         onLoad: 'login-required',
        checkLoginIframe: true,
        redirectUri: ValidateUrl.setURL(),
        checkLoginIframeInterval: 60,
      },
      bearerExcludedUrls: ['/unsecure', 'us1.locationiq.com/v1'],
  
      loadUserProfileAtStartUp: true,
    }).then(() => {
      const permission = JSON.parse(localStorage.getItem('permissions'));
  
      if (permission) {
        const userType = localStorage.getItem('userType') ? Number(localStorage.getItem('userType')) : null;
        let alternateId;
        let sameIdCheck = false;
        keycloak.loadUserProfile().then(profile => {
          if (profile['attributes']) {
            alternateId = profile['attributes'].alternateId ? profile['attributes'].alternateId[0] : null;
            if (alternateId == permission.userId) {
              sameIdCheck = true;
            }
          }
        });
        if ((keycloak.getUsername() != permission.userId && userType !== 19) ||
            (sameIdCheck && userType === 19)) {
          localStorage.removeItem('UserLoggedInCount');
          localStorage.removeItem('permissions');
          localStorage.removeItem('originSettings');
          localStorage.removeItem('isSuperAdmin');
          localStorage.removeItem('agentName');
          localStorage.removeItem('originName');
          localStorage.removeItem('originIdentifiers');
          localStorage.removeItem('userType');
          localStorage.removeItem('userValue');
          localStorage.removeItem('isStandardAdmin');
          localStorage.removeItem('allowedProducts');
          //Brazil coffee
          document.cookie = "languageISOCode= ; expires=Thu, 18 Dec 2013 12:00:00 UTC";
          localStorage.removeItem('staticText');
          if (document.domain.indexOf('olamdirect.com') != -1) {
            document.cookie = "googtrans= ; domain=.olamdirect.com; expires=Thu, 18 Dec 2013 12:00:00 UTC";
          }
          document.cookie = "googtrans= ; expires=Thu, 18 Dec 2013 12:00:00 UTC";
        }
      }
      let insta = keycloak.getKeycloakInstance()
      localStorage.setItem('email', insta.profile.username);
      this.count = localStorage.getItem('UserLoggedInCount') ? Number(localStorage.getItem('UserLoggedInCount')) + 1 : 1;
      localStorage.setItem('UserLoggedInCount', this.count);
    }).catch(err => {
      if (err.includes('interaction_required')) {
        alert('You are not authorized to access this application.Please contact Olam Direct Team.');
      } else {
        alert('Unexpected error.Please contact Olam Direct Team.');
      }
      keycloak.logout();
    });
    }


    
    // return () => keycloak.init({
    //     config: environment.keycloakConfig,
    //     initOptions: {
    //         onLoad: 'login-required',
    //         checkLoginIframe: true,
    //         redirectUri: ValidateUrl.setURL(),
    //     },
    //     bearerExcludedUrls: ['/unsecure'],
    //     loadUserProfileAtStartUp: true,
    //     enableBearerInterceptor: true
    // })

}