import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import * as _ from 'lodash';
import * as CryptoJS from 'crypto-js';
import { BehaviorSubject } from 'rxjs';
declare var require: any;
const SecureStorage = require('secure-web-storage');
const SECRET_KEY = 'secret_key';
import { KeycloakService } from 'keycloak-angular';

@Injectable()
export class AppSharedService {
  private messageSource = new BehaviorSubject('landing');
  public appLanguage = new BehaviorSubject('');
  public currentMessage = this.messageSource.asObservable();

  public leftSidenavMenu: any[] = [
    { url: '', class: 'glyphicon glyphicon-home', text: 'Home', active: true }
   
  ];

  public sidenavMenu = new BehaviorSubject<any>(this.leftSidenavMenu);
  public updateRoute: BehaviorSubject<any>;
  public mainScroll = new BehaviorSubject("");
  public sideNavResize = new BehaviorSubject("");

  public secureStorage = new SecureStorage(localStorage, {
    hash: function hash(key: any) {
      key = CryptoJS.SHA256(key,{});
      return key.toString();
    },
    // Encrypt the localStorage data
    encrypt: function encrypt(data: any) {
      data = CryptoJS.AES.encrypt(data, SECRET_KEY);
      data = data.toString();
      return data;
    },
    // Decrypt the encrypted data
    decrypt: function decrypt(data: any) {
      data = CryptoJS.AES.decrypt(data, SECRET_KEY);
      data = data.toString(CryptoJS.enc.Utf8);
      return data;
    }
  });



  constructor(private keycloakService: KeycloakService) {
    this.sidenavMenu.asObservable();
    this.updateRoute = new BehaviorSubject<any>({});
  }

  public updateSidenavMenu(sidemenuData: Array<any>) {
    this.sidenavMenu.next(sidemenuData);
  }

  get storedRoute() {
    return this.updateRoute.asObservable();
  }

  storeRoute(data: any) {
    this.updateRoute.next(data);
  }

  changeMessage(message: string) {
    this.messageSource.next(message)
  }

  openConfirm(options: any) {
    return new Promise((resolve, reject) => {
      Swal.fire({
        title: options.title,
        text: options.message,
        showCloseButton: false,
        showCancelButton: true,
        confirmButtonColor: '#dc582a',
        cancelButtonColor: '#6C5D56',
        confirmButtonText: 'Yes',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      }).then((result) => {
        if (result.value) {
          resolve(true)
        } else if ((result.dismiss === Swal.DismissReason.cancel) || (result.dismiss === Swal.DismissReason.close) || (result.dismiss === Swal.DismissReason.esc)) {
          resolve(false)
        }
      });
    })

  }

  openAlert(options: any) {
    Swal.fire({
      title: options.title,
      text: options.message,
      showClass: {
        popup: 'animated fadeInDown faster'
      },
      hideClass: {
        popup: 'animated fadeOutUp faster'
      }
    })
  }
 

    setNewTokenToIframe(iFrameId?: any){
        //sending new token to iframe/ofis-common-ot to update token
        let token = this.secureStorage.getItem('token');
        
        if(token !== this.keycloakService.getKeycloakInstance().token){
            this.secureStorage.setItem('token',this.keycloakService.getKeycloakInstance().token);
            token = this.secureStorage.getItem('token');
        }
        
        let id = iFrameId? iFrameId : this.getIFrameID();
        if(id && document.getElementById(id)){
            let iFrame = document.getElementById(id);
            iFrame['contentWindow']?.postMessage({updatedToken: token}, "*");
        }
    }
    getIFrameID(){
        if(document.getElementsByTagName("iframe") && document.getElementsByTagName("iframe")[0]){
            return document.getElementsByTagName("iframe")[0]['id'];
        }
        return null;
    }

}
