import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppSharedService} from 'src/app/services/shared.service';


export interface AuthDetails {
    display_name: string;
    access_token: string;
}


@Injectable({
    providedIn: 'root'
})

export class AuthenticationService {

    public isLoggedInSubject = new BehaviorSubject(this.hasToken());
    public loginStatus = new BehaviorSubject(null)
    public userData = new BehaviorSubject(false);
    public userName = new BehaviorSubject('');
    public readCookie: AuthDetails;

    constructor(private router: Router, private appSharedService: AppSharedService, private keycloakService: KeycloakService) {
        this.changeData(this.keycloakService.getKeycloakInstance().token);
    }

    // set isLoggedIn method to get authentication
    isLoggedIn() {
        return this.isLoggedInSubject.asObservable();
    }

    changeLoginStatus(loginStatus: any) {
        this.loginStatus.next(loginStatus)
    }
    changeData(userData: any) {
        this.userData.next(userData)
    }
    // set private helper methods to get access token
    private hasToken(){
        return this.keycloakService.getKeycloakInstance().token;
    }

    // Incase of redirecting to Angular login page.
    login(redirect?: string) {
        const _redirect = redirect ? redirect : this.router.url;
    }

    // logout from angular application
    logout() {
        this.keycloakService.clearToken();
        this.keycloakService.logout();
        this.userData.next(null);
        this.changeLoginStatus("loggedOut");
        this.changeData(null);
        localStorage.setItem('logout','true');
    }

}
