import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { LoginComponent } from './views/pages/user/login/login.component';
import { ForgotPasswordComponent } from './views/pages/user/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './views/pages/user/change-password/change-password.component';
import { ContainerModule } from './views/container/container.module';
import { HttpClientModule } from '@angular/common/http';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { initializeKeycloak } from 'src/app/core/AppInit';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppSharedService } from './services/shared.service';
import { ModalPopupComponent } from './views/shared/modal-popup/modal-popup.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorHandlingComponent } from './views/pages/error-handling/error-handling.component';
import { DashboardModule } from './views/pages/dashboard/dashboard.module';



@NgModule({
  declarations: [
    AppComponent,
    ChangePasswordComponent,
    ModalPopupComponent,
    // LoginComponent,
    ForgotPasswordComponent,
    ErrorHandlingComponent
    // UserListComponent,
    // MasterDataComponent,
    // ViewInvestorComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    KeycloakAngularModule,
    ContainerModule,
    HttpClientModule,
    //UserManagementRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
  ],
  providers: [  KeycloakService, AppSharedService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
