import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/services/data.service';
import { LoaderService } from 'src/app/services/LoaderSevice';
import { ModalPopupComponent } from 'src/app/views/shared/modal-popup/modal-popup.component';
declare function googleTranslateElementInit(): void;

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  public email: string = '';
  submitted: boolean = false;
  isInvalidEmail: boolean = false;
  constructor(private dataservice: DataService, private modalService: NgbModal, private loaderService: LoaderService) { }

  ngOnInit(): void {
  }
  ngAfterViewChecked() {
    console.log("translator");
    try {
      googleTranslateElementInit();
      //this._cd.detectChanges();
    } catch (e) {
      console.log(e, "google translator erro");
    }
  }
  sendLinkForResetPassword() {
    if(this.email != '') this.validateEmail(this.email);
    if (this.email == '' || this.isInvalidEmail) {
      this.submitted = true;
      return;
    } else {
      this.submitted = false;
      this.loaderService.isLoading.next(true);
      this.dataservice.sendLinkForResetPassword(this.email).subscribe((res: any) => {
        if (res?.message == 'Email Sent Successfully') {
          this.email = '';
          this.openModal('success', "Success", res.message);
        } else {
          this.openModal('error', "Error", res.message);
        }
        this.loaderService.isLoading.next(false);
      }, err => {
        this.loaderService.isLoading.next(false);
      });
    }
  }
  openModal(type, msg1, msg2) {
    const modalRef = this.modalService.open(ModalPopupComponent);
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.message1 = msg1;
    modalRef.componentInstance.message2 = msg2;
  }

  validateEmail(val) {
    let regax = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    let response = val?.match(regax);
    if (response != null) {
      let responseEmail = response[0].split('@');
      let emailArr = responseEmail[1]?.split('.');
      let duplicates = emailArr.filter((item, index) => emailArr.indexOf(item) != index);
      if (duplicates?.length > 0) {
        this.isInvalidEmail = true;
      } else {
        this.isInvalidEmail = false;
      }
    } else {
      this.isInvalidEmail = true;
    }
  }

}
