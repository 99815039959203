import { Injectable, ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LoaderService {
    showLoaderCount: number = 0;
    hideLoaderCount: number = 0;
    public loading = false;
    isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private isEdit: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    currentMessage = this.isEdit.asObservable();

    constructor() {
        //console.log("loader constructor")
    }

    changeMessage(message: boolean) {
        this.isEdit.next(message)
      }

    showLoader(): void {
        this.showLoaderCount += 1;
        this.loading = true;
        //console.log("show loader", this.showLoaderCount)
    }

    hideLoader(): void {
        this.hideLoaderCount += 1;
        // console.log("hide loader", this.hideLoaderCount);
        if (this.showLoaderCount == this.hideLoaderCount) {
            this.loading = false;
        }
    }

}
